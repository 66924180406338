/**
 * 路由拦截
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from "@ROUTE/routes";
import RouterFilter from "@/resources/js/router-filter";

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: function (to, from, savedPosition) {
    return {
      x: 0,
      y: 0
    }
  }
})

router.beforeEach((to, from, next) => {
  RouterFilter.routerFilterChain.execute(to, from, next);
});

router.afterEach(function (to, from) {
  RouterFilter.routerFilterChain.afterEach(to, from);
})

export default router
