/**
 * Vux挂载
 */
import Vue from 'vue'
import Vuex from 'vuex'
import account from '@INITDATA/modules/account'
import setting from '@INITDATA/modules/setting'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    account: account,
    setting: setting,
  }
})
