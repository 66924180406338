/**
 * 用户操作js
 */

import axios from "@/resources/js/request";

const request = axios.request;
/**
 * 用户注册
 * @param phone 用户手机号
 * @param password 用户密码
 * @param companyName 企业名称
 * @param userType
 * @param account
 * @param email
 * @param code
 * @returns {Promise<unknown>}
 */
export const register = ({phone, password, companyName, userType, account, email, code,}) => {
  return request.doPost('/register', {
    phone, password, companyName, userType, account, email, code,
  });
}

/**
 * 校验手机号唯一性
 * @param phone 手机号
 * @returns {*}
 */
export const checkUniquePhone = ({phone}) => {
  return request.doGet('/checkUniquePhone', {
    phone
  });
}
/**
 * 用户登录
 * @param phone 登录手机号
 * @param password 登录密码
 * @param type 身份类型
 * @returns {Promise<unknown>}
 */
export const login = ({phone, password, usertype}) => {
  return request.doPost('login', {
    password, phone, usertype
  });
}

/**
 * 手机验证码用户登录
 * @param phone 登录手机号
 * @param code 验证码
 * @param type 身份类型
 * @returns {Promise<unknown>}
 */
export const smslogin = ({phone, code, usertype}) => {
  return request.doPost('smslogin', {
    phone, code, usertype
  });
}

/**
 * 忘记密码
 * @param phone 登录手机号
 * @param code 验证码
 * @param password 新密码
 * @returns {Promise<unknown>}
 */
export const forgetPassword = ({phone, code, password}) => {
  return request.doPost('forgetPassword', {
    phone, code, password
  });
}

export const loadUserByToken = ({token}) => {
  return request.doGet('loadUser', {
    token,
  })
}

export const getUserInfo = ({adminuid}) => {
  return request.doGet('getUserInfo', {
    adminuid
  })
}
/**
 * 修改用户信息
 * @param data
 * @returns {*}
 */
export const updateUserInfo = (data) => {
  return request.doPost('updateUserInfo', data)
}
/**
 * 修改用户绑定手机号信息
 * @param data
 * @returns {*}
 */
export const updatePhone = (data) => {
  return request.doPost('updatePhone', data)
}

/**
 * 获取Ai资讯详情
 * @param data
 * @returns {*}
 */
export const getUseraiConsultDetails = (data) => {
  return request.doPost('getUseraiConsultDetails', data)
}

/**
 * 修改密码
 * @param data
 * @returns {*}
 */
export const updatePwd = (data) => {
  return request.doPost('updatePwd', data)
}

/**
 * 获取首页轮播图等信息
 * @param data
 * @returns {*}
 */
export const getCarousel = () => {
  return request.doPost('getCarousel')
}

/**
 * 获取友情链接信息
 * @param data
 * @returns {*}
 */
export const getlinkList = () => {
  return request.doPost('getlinkList')
}

/**
 * 获取获取首页轮播图
 * @param data
 * @returns {*}
 */
export const getloginCarousels = () => {
  return request.doPost('getloginCarousels')
}

/**
 * 获取省市区数据
 * @param data
 * @returns {*}
 */
export const getAddressList = () => {
  return request.doPost('getAddressList')
}

/**
 * 获取客户消息
 * @param data
 * @returns {*}
 */
export const getClientInfo = () => {
  return request.doPost('getClientInfo')
}

/**
 * 获取登录日志
 * @returns {Promise<unknown>}
 */
export const getloginRecordList = (data) => {
  return request.doPost('/csstaff/getloginRecordList', data);
}

/**
 * 获取协议
 * @param data
 * @returns {*}
 */
export const getagreement = ({agreementname}) => {
  return request.doGet('getagreement', {
    agreementname
  })

}


