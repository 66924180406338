export default [
  {
    path: '/',
    name: 'default',
    component: () => import('@PAGE/common/WrapLayout'),
    redirect: '/index',
    children: [
      {
        path: '/login',
        name: 'Index',
        component: () => import('@PAGE/server/login/Login'),
        children: []
      },
      {
        path: '/register',
        name: 'Register',
        component: () => import('@PAGE/server/login/Register'),
        children: []
      },
      {
        path: '/forgetpassword',
        name: 'Forgetpassword',
        component: () => import('@PAGE/server/login/ForgetPassword'),
        children: []
      },
      {
        path: '/feedback',
        name: '意见反馈',
        component: () => import('@PAGE/common/Feedback'),
        children: []
      },
      {
        path: '/helpCenter',
        name: '帮助中心',
        component: () => import('@PAGE/common/HelpCenter'),
        children: []
      },
    ],
    meta: {}
  },
  {
    path: '/clientdemo',
    name: 'clientdemo',
    component: () => import('@PAGE/clientdemo/ClientDemo'),
    children: [],
    meta: {}
  },
  {
    path: '/index',
    name: 'Index',
    component: () => import('@PAGE/server/home/Index'),
    children: [],
    meta: {}
  },
  {
    path: '/client',
    name: 'client',
    component: () => import('@PAGE/client/Index'),
    meta: {},
    children: []
  },
  {
    path: '/showAiDetails',
    name: 'showAiDetails',
    component: () => import('@PAGE/server/home/AiDetails'),
    meta: {},
    children: []
  },
  {
    path: '/smallwindow',
    name: 'smallwindow',
    component: () => import('@PAGE/client/SmallWindow'),
    meta: {},
    children: []
  },
  {
    path: '/www',
    name: 'alonesmallwindow',
    component: () => import('@PAGE/client/AloneSmallWindow'),
    meta: {},
    children: []
  },
  {
    path: '/vclient/robot',
    name: 'client',
    component: () => import('@PAGE/client/RobotCsService'),
    props: true
  },
  {
    path: '/leavingMessage',
    name: 'leavingMessage',
    component: () => import('@PAGE/client/LeavingMessage'),
    props: true
  },
  {
    path: '/server',
    name: 'server',
    component: () => import('@PAGE/server/cs-workboard/CsIndex.vue'),
    meta: {
      requireAuth: true
    },
    children: []
  },
  {
    path: '/custindex',
    name: 'custindex',
    component: () => import('@PAGE/server/manger-workboard/ManageIndex'),
    redirect: '/userinfo',
    meta: {
      requireAuth: true
    },
    children: [
      {
        path: '/userinfo',
        name: 'userinfo',
        component: () => import('@PAGE/server/manger-workboard/account/UserInfo'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/projectinfo',
        name: 'projectinfo',
        component: () => import('@PAGE/server/manger-workboard/project/ProjectInfo')
        , meta: {
          requireAuth: true
        }
      },
      {
        path: '/csstaffinfo',
        name: 'csstaffinfo',
        component: () => import('@PAGE/server/manger-workboard/csstaff/CsStaffInfo')
        , meta: {
          requireAuth: true
        }
      },
      {
        path: '/refcsstaffproject',
        name: 'refcsstaffproject',
        component: () => import('@PAGE/server/manger-workboard/refcssraffproject/RefCsStaffProject')
        , meta: {
          requireAuth: true
        }
      },
      {
        path: '/hotissues',
        name: 'hotissues',
        component: () => import('@PAGE/server/hotissues/HotIssues')
        , meta: {
          requireAuth: true
        }
      },
      {
        path: '/quicklinks',
        name: 'quicklinks',
        component: () => import('@PAGE/server/manger-workboard/quicklinks/QuickLinks')
        , meta: {
          requireAuth: true
        }
      },
      {
        path: '/csquickreply',
        name: 'csquickreply',
        component: () => import('@PAGE/server/manger-workboard/quicklinks/CsQuickReply')
        , meta: {
          requireAuth: true
        }
      },
      {
        path: '/robotset',
        name: 'robotset',
        component: () => import('@PAGE/server/manger-workboard/robot/RobotInfo')
        , meta: {
          requireAuth: true
        }
      },
      {
        path: '/autoreply',
        name: 'autoreply',
        component: () => import('@PAGE/server/manger-workboard/autoreply/AutoReply')
        , meta: {
          requireAuth: true
        }
      },
      {
        path: '/leave',
        name: 'leave',
        component: () => import('@PAGE/server/manger-workboard/leave/LeaveMessage')
        , meta: {
          requireAuth: true
        }
      },{
        path: '/complaint',
        name: 'complaint',
        component: () => import('@PAGE/server/manger-workboard/userbehavior/Complaint')
        , meta: {
          requireAuth: true
        }
      }, {
        path: '/clientevaluate',
        name: 'clientevaluate',
        component: () => import('@PAGE/server/manger-workboard/userbehavior/ClienteValuate')
        , meta: {
          requireAuth: true
        }
      },{
        path: '/robotmsgreload',
        name: 'robotmsgreload',
        component: () => import('@PAGE/server/manger-workboard/userbehavior/RobotMsgReLoad')
        , meta: {
          requireAuth: true
        }
      },
    ]
  },
]
