/**
 * 存储账户信息
 */
export default {
  namespaced: true,
  state: {
    user: null
  },
  mutations: {
    setUser: function (state, user) {
      console.log("登入了---->",user)
      state.user = user;
    }
  },
  getters: {
    getUser: state => {
      return state.user;
    }
  }
}
