import request from "./resources/js/request";

require('./resources/js/prototype-enhance')
import "@/resources/js/initialization"
import "core-js/stable";
import "regenerator-runtime/runtime";
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './initdata'
import common from './resources/plugin/common'
import ElementUI from 'element-ui'
import language from './resources/languages/index'
import Meta from 'vue-meta'
import axios from "axios";
// 修改 el-dialog 默认点击遮照为不关闭
ElementUI.Dialog.props.closeOnClickModal.default = false
axios.defaults.withCredentials = true
Vue.config.productionTip = false;
Vue
.use(common)
.use(ElementUI)
.use(Meta)
language.loadLanguageAsync().then(() => {
  new Vue({
    router,
    i18n: language.i18n,
    store,
    render: h => h(App)
  }).$mount('#app')
});

router.beforeEach((to, from, next) => {
  setTimeout(()=>{
    var _hmt = _hmt || [];
    (function() {
      //每次执行前，先移除上次插入的代码
      document.getElementById('baidu_tj') && document.getElementById('baidu_tj').remove();   //判断有没有 baidu_tj  元素，有就删除
      var hm = document.createElement("script");
      hm.src = "https://hm.baidu.com/hm.js?6f5f49f2eb5bbbd33e08aa26f36ec0c8";
      hm.id = "baidu_tj"
      var s = document.getElementsByTagName("script")[0];
      s.parentNode.insertBefore(hm, s);
    })();
  },0);
  next();
})
