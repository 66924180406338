/**
 * 封装请求js
 */
import axios from 'axios'
import config from './config'
import {Message} from 'element-ui'

const repeatedSubmitCache = [];
const unCheckApi = ['login','updatePhone', 'smslogin', 'register', 'checkUniquePhone', 'forgetPassword', '/config/website', '/websocket/getOnLineService', '/quicklinks/getsomequicklinks'
  , '/websocket/sendOneWebSocket'
  , '/websocket/refreshcsheartbeat'
  , '/websocket/csDisconnect'
  , '/websocket/updatelinestatus'
  , '/websocket/saveRobotMessage'
  , '/websocket/refreshclientheartbeat'
  , '/websocket/decryptTokenkey'
  , '/websocket/addOrUpdateUserInfo'
  , '/websocket/addComplaint'
  , '/websocket/getConfigset'
  , '/websocket/getAutoreply'
  , '/robot/addLeaveMessage'
  , '/robot/getAutoReply'
  , '/robot/getrobotinfo'
  , '/uploads/uploadFile'
  , '/captcha/sendPhoneCode'
  , 'getUseraiConsultDetails'
  , '/websocket/addClientEvaluate'
  , 'getloginCarousels', 'loadUser', 'getCarousel', 'getlinkList', 'vclientV2', 'getagreement'
  , 'getClientInfo'
  ,'/csstaff/getloginRecordList'
];

class HttpRequest {
  constructor(baseUrl, APP_ID = '', isDebug = false) {
    this.baseUrl = baseUrl
    this.APP_ID = APP_ID
    this.isDebug = isDebug
    // this.queue = {}
  }

  getInsideConfig() {
    return {
      baseURL: this.baseUrl,
       headers: {
          token: localStorage.getItem("token") || ''
        }
    }
  }

  stringify(obj) {
    if (obj instanceof Array) {
      const arr = []
      for (let i = 0; i < obj.length; i++) {
        arr[i] = this.stringify(obj[i])
      }
      return arr
    } else if (typeof obj === 'object') {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          obj[key] = this.stringify(obj[key])
        }
      }
    } else if (typeof obj === 'number') {
      obj = obj + ''
    }
    return obj
  }

  stopRepeatRequest(request) {
    let cancel
    request.cancelToken = new axios.CancelToken(function (c) {
      cancel = c;
    });
    let reqData = '';
    if (request.method.toUpperCase() === 'GET') {
      reqData = request.params;
    } else if (request.method.toUpperCase() === 'POST') {
      reqData = request.data;
    }
    let dataJson = JSON.stringify(reqData);
    if (repeatedSubmitCache.includes(dataJson)) {
      if (cancel) {
        cancel('您的操作太频繁，请稍后重试！');
        return;
      }
    }
    repeatedSubmitCache.push(dataJson);
  }

  allowRequest(options) {
    let dataJson = options.data;
    let index;
    if ((index = repeatedSubmitCache.indexOf(dataJson)) !== -1) {
      repeatedSubmitCache.splice(index, 1);
    }
  }

  delayAllowRequest(options) {
    setTimeout(() => {
      this.allowRequest(options);
    }, 1000);
  }

  interceptors(instance, url) {
    const self = this
    instance.interceptors.request.use(request => {
      if (unCheckApi.includes(url)) {
        request.headers.token = '';
      }
      if (request.data) {
        request.data.appid = self.APP_ID;
        request.data = self.stringify(request.data);
      }
      //self.stopRepeatRequest(request);
      if (self.isDebug) {
        console.debug(request.data);
      }
      return request
    }, error => {
      Message.error('网络请求异常')
      console.error(error)
      return Promise.reject(error)
    })
    instance.interceptors.response.use(response => {
      self.delayAllowRequest(response.config);
      if (self.isDebug) {
        console.debug(response)
      }
      return response
    }, error => {
      // console.warn(error)
      self.delayAllowRequest(error.config);
      if (axios.isCancel(error)) {
        console.log(error.message);
        return Promise.reject();
      }
      let errorCode = error && error.response && error.response.status || null;
      if (errorCode === 405) {
        Message.error('请求不被允许')
      } else if (errorCode === 404) {
        Message.error('内容未找到')
      } else {
        Message.error('网络请求异常')
      }
      return Promise.reject(error)
    })
  }

  request(options) {
    options = {
      ...this.getInsideConfig(),
      ...options
    }
    const instance = axios.create(options)
    this.interceptors(instance, options.url)
    return instance(options).then(res => {
      let {headers: {token}} = res;
      if (token) {
        localStorage.setItem("token", token);
      }
      if (res.status === 200) {
        let data = res.data;
        data.retCode = String(data.retcode);
        data.retDesc = String(data.retmsg);
        delete data.retcode;
        delete data.retdesc;
        if (data.retCode === '8888') {
          return Promise.resolve(data);
        } else if (data.retCode === '9999') {

          Message.error(data.retDesc || '操作失败');
          return Promise.reject(data);
        } else if (data.retCode === '9005') {

          this.$router.push({path: '/login'})
          return Promise.reject(data);
        } else {
          Message.error(data.retDesc || '操作失败');
          return Promise.reject(data);
        }
      }
    })
  }

  doPost(url, params) {
    const requestParams = {}
    requestParams.method = 'post'
    requestParams.url = url || config.URL
    requestParams.data = params
    requestParams.responseType="text"
    return this.request(requestParams)
  }

  doGet(url, params) {
    const requestParams = {}
    requestParams.method = 'get'
    requestParams.url = url || config.URL
    requestParams.params = params
    return this.request(requestParams)
  }
}

const httpRequest = new HttpRequest(config.BASE_URL, config.APP_ID,
  config.isDebug)

export default {
  request: httpRequest
}
