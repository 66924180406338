/**
 * 路由过滤js
 */
import Filter from './interceptor'
import store from "@INITDATA/index";
import {loadUserByToken} from '@/apis/index'

function setTitle(route) {
  const websiteTitle = store.getters['setting/getWebSiteTitle'];
  if (websiteTitle instanceof Promise) {
    websiteTitle.then(res => {
      document.title = `${res} - ${route.name}`
    })
  } else {
    document.title = `${websiteTitle}`
  }
}

class RouterFilterChain extends Filter.FilterChain {
  execute(to, from, next) {
    if (this.filters && this.filters.length > 0) {
      let doNext = (current) => {
        if (current < this.filters.length - 1) {
          doFilter(this.filters[++current], current);
        } else {
          next();
        }
      }
      let doFilter = (filter, current) => {
        let ret = filter.doFilter(to, from, next);
        if (ret instanceof Promise) {
          ret.then(res => {
            if (res !== false) {
              doNext(current);
            }
          });
        } else {
          if (ret !== false) {
            doNext(current);
          }
        }
      }
      let current = 0;
      let filter = this.filters[current];
      doFilter(filter, current);
      return;
    }
    next();
  }

  afterEach(to, from) {
    setTitle(to);
  }
}

class RouterFilter extends Filter.Filter {
  doFilter(to, from, next) {

  }
}

const authenticationFilter = new RouterFilter();
// 登录拦截
const loginFilter = new RouterFilter();
loginFilter.doFilter = (to, from, next) => {
  if (store.getters['account/getUser']) {
    return true;
  }
  let token = localStorage.getItem("token");
  if (!token) {
    return true;
  }
  return loadUserByToken({
    token: token
  }).then(res => {
    console.log("/////token---->",token)
    console.log("//////////",res)
    let {data} = res;
    if (data) {
      store.commit('account/setUser', data);
    }
  }).catch(() => {
    return true;
  });
}
// 用户登录校验拦截
authenticationFilter.doFilter = (to, from, next) => {
  let needLogin = () => {
    return checkRouter(to, (route) => route.meta && route.meta.requireAuth);
  }
  console.log(!store.getters['account/getUser'])
  if (needLogin() && !store.getters['account/getUser']) {
    next({path: `/login?t=${new Date().getTime()}`})
  }
}
const checkRouter = (route, callback) => {
  let target = route.matched.filter(
    item => item.path === route.path || item.regex.test(route.path)
  )[0];
  if (target) {
    do {
      if (callback(target)) {
        return true;
      }
    } while ((target = target.parent));
  }
}

// 拦截器链
const routerFilterChain = new RouterFilterChain();
routerFilterChain.addFilter(loginFilter);
routerFilterChain.addFilter(authenticationFilter);

// 拦截器链添加拦截器

export default {
  routerFilterChain
}
