<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
  export default {
    name: 'app',
    components: {},
    methods: {},
    beforeMount() {
    }
  }
</script>

<style lang="scss">
  @import '~element-ui/lib/theme-chalk/index.css';
  @import "resources/css/common";
  @import "resources/css/main.css";
  @import "resources/css/icon";

  #app {
    height: 100vh;
  }
</style>
