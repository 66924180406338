/**
 * 配置js
 */
import axios from "@/resources/js/request";
import initdata from '@/initdata'

const request = axios.request;

/**
 * 加载默认配置
 * @returns {Promise}
 */
export const getWebSiteConfig = () => {
  return request.doGet('/config/website').then(res => {
    let {retdata} = res;
    console.log(retdata)
    initdata.commit('setting/setContactUsQQ', retdata.contactUsQQ);
    initdata.commit('setting/setContactUsPhone', retdata.contactUsPhone);
    initdata.commit('setting/setWebsiteTitle', retdata.webSiteTitle);
    initdata.commit('setting/setContactUsTel', retdata.contactUsTel);
    initdata.commit('setting/setContactUsTime', retdata.contactUsTime);
    initdata.commit('setting/setTechnologyConsultingPhone',
      retdata.technologyConsultingPhone);
    initdata.commit('setting/setMediaCooperation', retdata.mediaCooperation);
    initdata.commit('setting/setEmail', retdata.email);
    initdata.commit('setting/setPostcode', retdata.postcode);
    initdata.commit('setting/setContactUsername', retdata.contactUsername);
    initdata.commit('setting/setCompanyAddress', retdata.companyAddress);
    initdata.commit('setting/setTechnicalSupport', retdata.technicalSupport);
    initdata.commit('setting/setOfficialSite', retdata.officialSite);
    initdata.commit('setting/setBusinessQrCode',retdata.businessQrCode);
    initdata.commit('setting/setRegisterPageImage', retdata.registerPageImage);
    initdata.commit('setting/setAppPath', retdata.appPath);
    initdata.commit('setting/setHeartbeatIntervalTime', Number(retdata.heartbeatIntervalTime));
  });
}
