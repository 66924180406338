/**
 * 本地开发环境js
 * @type {{isDebug: boolean, ORIGIN_PRE: string, BASE_URL: string, TARGET_URL: string}}
 */
const dev = {
  isDebug: false,
  ORIGIN_PRE: '/api',
  BASE_URL: '/api',
  TARGET_URL: 'http://localhost:8082/',
};
// 生产环境
const pro = {
  isDebug: false,
  BASE_URL: '/srapi',
};
const choose = () => {
  if (process.env.NODE_ENV === 'development') {
    return {
      ...dev,
      isDev: true
    }
  } else if (process.env.NODE_ENV === 'production') {
    return {
      ...pro,
      isDev: false
    }
  } else {
    return {}
  }
};
const env = choose();
const config = {
  ...env,
  APP_ID: 'SC_WEB',
  URL: '/doIsrRecvAction',
  API_ROOT: env.BASE_URL,
  UPLOAD_API: env.BASE_URL + '/uploads/uploadFile',
  UPLOAD_API2: env.BASE_URL + '/uploads/uploadCKEditor?path=textimg',
  version: '1.0.2'
};
module.exports = config;
