import upload from "@/resources/js/upload";
import {format} from "date-fns";
import {parseDate} from '@/resources/js/date-util'
import Vuex from 'vuex'

export default {
  install(Vue) {
    // 全局事件管理器
    Vue.prototype.$Bus = new Vue();

    Vue.mixin({
      data() {
        return {
          fileUploadFunction: upload,
        }
      },
      directives: {},
      filters: {},
      created() {
      },
      destroyed() {
      },
      beforeMount() {
      },
      methods: {},
      computed: {
        ...Vuex.mapGetters({
          user: 'account/getUser'
        }),
        dateToString() {
          return (value, patten = 'yyyy-MM-dd HH:mm:ss') => {
            if (!value) {
              return '';
            }
            let date = parseDate(value);
            if (date) {
              return format(date, patten);
            }
            return '';
          }
        },
        timeToString() {
          return (value, patten = 'MM-dd HH:mm:ss') => {
            if (!value) {
              return '';
            }
            let date = parseDate(value);
            if (date) {
              return format(date, patten);
            }
            return '';
          }
        },
      }
    })
  }
}
