/**
 * 存储字段信息
 */
export default {
  namespaced: true,
  state: {
    websiteTitle: '', // 网站标题
    contactUsQQ: '', // 联系我们qq
    language: '', // 语言
    contactUsPhone: '', // 联系客服手机
    contactUsTel: '', // 联系客服电话
    contactUsTime: '', // 联系客服时间
    adminWebSite: '', // 后台地址
    technologyConsultingPhone: '', // 技术咨询电话
    mediaCooperation: '', // 媒体合作
    heartbeatIntervalTime:10, //心跳
    email: '', // 邮箱
    postcode: '', // 邮编
    contactUsername: '', // 联系人
    companyAddress: '', // 公司地址
    technicalSupport: '', // 技术支持
    officialSite: '', // 官方网站
    businessQrCode: '', // 商务二维码
    helloWord: '', // Hi，欢迎来到XXXX
    friendLinks: '', // Hi，欢迎来到XXXX
    advertises: '',
    defaultAvatar: '', // 默认用户头像
    appPath: '', // 项目接口地址
    registerpageimage: '', // 注册图片
  },
  mutations: {
    setDevice: function (state, isMobile) {
      state.isMobile = isMobile
    },
    setDefaultAvatar: function (state, avatar) {
      state.defaultAvatar = avatar;
    },
    setWebsiteTitle: function (state, title) {
      localStorage.setItem('websiteTitle', title);
      state.websiteTitle = title;
    },
    setContactUsQQ: function (state, qq) {
      state.contactUsQQ = qq
    },
    setLanguage: function (state, lang) {
      localStorage.setItem('lang', lang)
      state.language = lang
    },
    setHeartbeatIntervalTime:function (state , heartbeatIntervalTime){

      if (heartbeatIntervalTime!=null && heartbeatIntervalTime!==''){
        state.heartbeatIntervalTime = heartbeatIntervalTime
      }
    },
    setContactUsPhone: function (state, contactUsPhone) {
      state.contactUsPhone = contactUsPhone
    },
    setContactUsTel: function (state, contactUsTel) {
      state.contactUsTel = contactUsTel
    },
    setContactUsTime: function (state, contactUsTime) {
      state.contactUsTime = contactUsTime
    },
    setAdminWebSite: function (state, adminWebSite) {
      state.adminWebSite = adminWebSite
    },
    setTechnologyConsultingPhone: function (state, technologyConsultingPhone) {
      state.technologyConsultingPhone = technologyConsultingPhone
    },
    setMediaCooperation: function (state, mediaCooperation) {
      state.mediaCooperation = mediaCooperation
    },
    setEmail: function (state, email) {
      state.email = email
    },
    setPostcode: function (state, postcode) {
      state.postcode = postcode
    },
    setContactUsername: function (state, contactUsername) {
      state.contactUsername = contactUsername
    },
    setCompanyAddress: function (state, companyAddress) {
      state.companyAddress = companyAddress
    },
    setTechnicalSupport: function (state, technicalSupport) {
      state.technicalSupport = technicalSupport
    },
    setOfficialSite: function (state, officialSite) {
      state.officialSite = officialSite
    },
    setBusinessQrCode: function (state, businessQrCode) {
      state.businessQrCode = businessQrCode
    },
    setHelloWord: function (state, helloWord) {
      state.helloWord = helloWord
    },
    setFriendLinks: function (state, friendLinks) {
      state.friendLinks = friendLinks
    },
    setadvertises: function (state, advertises) {
      state.advertises = advertises
    },
    setRegisterPageImage: function (state, registerpageimage) {
      state.registerpageimage = registerpageimage
    },
    setAppPath: function (state, appPath) {
      state.appPath = appPath
    }
  },
  getters: {
    /**
     * 获取当前语言，如果没有设置任何语言，默认使用中文
     * @param state
     * @returns {string|string|*}
     */
    getLanguage: state => {
      if (state.language) {
        return state.language
      } else {
        const language = localStorage.getItem('lang')
        return language || 'zh-CN'
      }
    },
    getHeartbeatIntervalTime(){
      return this.state.heartbeatIntervalTime
    },
    getWebSiteTitle: (state) => {
      if (state.websiteTitle) {
        return state.websiteTitle
      } else {
        // const websiteTitle = localStorage.getItem('websiteTitle')
        // if (!websiteTitle) {
        //   return websiteTitle
        //   .then(res => {
        //     let {data: title} = res;
        //     localStorage.setItem('websiteTitle', title);
        //     state.websiteTitle = title;
        //     return title;
        //   });
        // }
        // state.websiteTitle = websiteTitle;
        // return websiteTitle;
        return "客服系统";
      }
    }
  }
}
