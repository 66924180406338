import Vue from 'vue'
import VueI18n from 'vue-i18n'
import initdata from '@/initdata/index'

Vue.use(VueI18n)
const loadedLanguages = {}
const i18n = new VueI18n({
  silentTranslationWarn: true
})
const setI18nLang = (lang, message) => {
  i18n.setLocaleMessage(lang, message)
  i18n.locale = lang
  return lang
}
const loadLanguageAsync = (lang = initdata.getters['setting/getLanguage']) => {
  return new Promise(resolve => {
    if (!loadedLanguages[lang]) {
      import(`@/resources/languages/${lang}`).then(message => {
        message = message.default
        loadedLanguages[lang] = message
        resolve(setI18nLang(lang, message))
      })
    } else {
      resolve(setI18nLang(lang, loadedLanguages[lang]))
    }
  })
}
export default {
  i18n: i18n,
  loadLanguageAsync: loadLanguageAsync
}
