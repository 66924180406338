/**
 * 时间工具js
 * @param diff
 * @returns {string}
 */
export const formatDuring = function (diff) {
  if (diff < 0) {
    return '';
  }
  let ret = '';
  let sec = 1000;
  let minute = sec * 60;
  let hour = minute * 60;
  let day = hour * 24;
  let d = Math.floor(diff / day);
  let h = Math.floor((diff % day) / hour);
  let m = Math.floor((diff % hour) / minute);
  let s = Math.floor((diff % minute) / sec);
  if (d) {
    ret += `${d}天`
  }
  if (h) {
    ret += `${h}时`
  }
  if (m) {
    ret += `${m}分`
  }
  if (ret === '') {
    return `${s}秒`
  }
  return ret;
}
export const parseDate = function (value) {
  if (!value) {
    return '';
  }
  if (typeof value === 'string' && value.includes('CST')) {
    const dateStr = value.split(' ');
    const strGMT = dateStr[0] + ' ' + dateStr[1] + ' ' + dateStr[2]
      + ' ' + dateStr[5] + ' ' + dateStr[3] + ' GMT+0800';
    return new Date(Date.parse(strGMT))
  }
  return new Date(value)
}
